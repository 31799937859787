<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Subscription</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <div class="w-full mb-5">
        <label class="block mb-2">Package Type</label>
        <!--        <v-select :options="packageOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="packageFilter" class="mb-5 md:mb-0" />-->
        <p class="font-semibold">{{type}}</p>
        </div>
        <div class="w-full mb-5">
        <vs-input
          label="Package Name"
          v-model="name"
          class="w-full"
          name="name"
          v-validate="'required|alpha_spaces'"
          data-vv-as="Name"/>
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>

        <div class="w-full mt-5 mb-5">
        <vs-input
          label="Price (Cents)"
          v-model="price"
          class="w-full"
          name="price"
          v-validate="'required|decimal:3'"
          data-vv-as="Price"/>
        <span class="text-danger text-sm" v-show="errors.has('price')">{{ errors.first('price') }}</span>
        </div>
        <div class="w-full mb-5">
        <vs-input
          label="Quota Text"
          v-model="quotaText"
          class="w-full"
          name="quotaText"
          v-validate="'required'"
        data-vv-as="Quota Text"/>
        <span class="text-danger text-sm" v-show="errors.has('quotaText')">{{ errors.first('quotaText') }}</span>
        </div>
        <div class="w-full mb-5">
        <vs-input
          label="Duration (Days)"
          v-model="duration"
          class="w-full"
          name="duration"
          v-validate="'required|numeric'"
        data-vv-as="Duration"/>
        <span class="text-danger text-sm" v-show="errors.has('duration')">{{ errors.first('duration') }}</span>
        </div>
        <div class="w-full mb-5" v-show="false">
        <vs-input
          label="Number of Centre"
          v-model="numberOfCenter"
          class="w-full"
          name="numberOfCenter"
          v-validate="'required'"
        data-vv-as="Number of Centre"/>
        <span class="text-danger text-sm" v-show="errors.has('numberOfCenter')">{{ errors.first('numberOfCenter') }}</span>
        </div>
        <div class="w-full mb-5" v-show="false">
        <vs-input
          label="Number of Children"
          v-model="numberOfChildren"
          class="w-full mb-5"
          name="numberOfChildren"
          v-validate="'required'"
          data-vv-as="Number of Children"/>
        <span class="text-danger text-sm" v-show="errors.has('numberOfChildren')">{{ errors.first('numberOfChildren') }}</span>
        </div>
        <div class="w-full mb-5">
          <vs-input
            label="Paypal Plan Id"
            v-model="planId"
            class="w-full mb-5"
            name="planId"
            v-validate="'required'"
            data-vv-as="Plan Id"/>
          <span class="text-danger text-sm" v-show="errors.has('planId')">{{ errors.first('planId') }}</span>
        </div>

        <div class="w-full mb-5">
          <vs-select
            placeholder="Features"
            multiple
            autocomplete
            class="w-full"
            label="Features"
            v-model="selectedFeatures"
          >
            <vs-select-item
              :key="feature._id"
              :value="feature._id"
              :text="feature.name"
              v-for="feature in featureList"
            />
          </vs-select>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        selectedFeatures: [],
        dataId: null,
        name: "",
        type: "",
        //rate: "",
        quotaText: "",
        buttonText: "",
        duration: "",
        numberOfCenter: "",
        numberOfChildren: "",
        planId: "",
        price: "",
        packageOptions:  [
          { label: 'Basic', value: 'Basic' },
          { label: 'Advance', value: 'Advance' },
          { label: 'Corporate', value: 'Corporate' },
        ],
        packageFilter: {label: 'All', value: 'all'},
        settings: { // perfect scrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        customError: "",
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
      featureList: {
        type: Array
      },
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.dataId = this.data._id
          //this.packageFilter = {label: this.data.type, value: this.data.type}
          this.name = this.data.name
          this.price = this.data.price
          this.quotaText = this.data.quotaText
          this.buttonText = this.data.buttonText
          this.duration = this.data.duration
          this.numberOfCenter = this.data.numberOfCenter
          this.numberOfChildren = this.data.numberOfChildren
          this.type = this.data.type
          this.planId = this.data.planId
          this.selectedFeatures = this.data.features
         // this.rate = this.data.rate
          this.initValues()
        }
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
          // && this.packageFilter.value != "all"
        //  && this.rate
          && this.quotaText
          && this.buttonText
          && this.duration
          && this.numberOfCenter
          && this.numberOfChildren
          && this.price
        && this.name
      }
    },
    methods: {
      ...mapActions("setting", [
        "updateSubscription"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.dataId = null
        this.type = ""
        this.name =""
        //this.rate =""
        this.quotaText = ""
        this.buttonText = ""
        this.duration = ""
        this.numberOfCenter = ""
        this.numberOfChildren = ""
        this.price = ""
        //this.packageFilter = {label: 'All', value: 'all'};
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            const data = {
              id: this.dataId,
              name: this.name,
             // type: this.packageFilter.value,
           //   rate: this.rate,
              quotaText: this.quotaText,
              buttonText: this.buttonText,
              duration: this.duration,
              numberOfCenter: this.numberOfCenter,
              numberOfChildren: this.numberOfChildren,
              price: this.price,
              planId: this.planId,
              features: this.selectedFeatures
            };
            this.customError = "";
            if (this.dataId !== null) {
              this.$vs.loading()
              this.updateSubscription(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.showMessage("Success", "Subscription Package updated successfully.", "success");
                    this.$emit('refreshData', true);
                  }
                  this.$vs.loading.close()
                })
                .catch(err => {
                  this.$vs.loading.close()
                  //console.error(err)
                })
            }
            this.$emit('closeSidebar')
            //this.initValues()
          }
        })
      },
    },
    created(){
    },
    mounted() {
    },
    components: {
      vSelect,
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
